var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page people" },
    [
      _c(
        "label",
        { staticClass: "categoryName" },
        [
          _c("i", {
            staticClass: "el-icon--home",
            on: {
              click: function($event) {
                return _vm.jumpPage({ name: "home" })
              }
            }
          }),
          _vm.cname
            ? [
                _c("i", { staticClass: "el-icon--right" }),
                _c(
                  "span",
                  {
                    staticClass: "category",
                    on: {
                      click: function($event) {
                        return _vm.backToCategory()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.cname))]
                )
              ]
            : _vm._e(),
          _c("i", { staticClass: "el-icon--right" }),
          _c("i", { staticClass: "el-icon-user-solid" })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "user-list" },
        _vm._l(_vm.users, function(user, index) {
          return _c(
            "div",
            { key: index, staticClass: "item" },
            [
              _c("Avatar", {
                attrs: {
                  src: _vm.formartImageUrl(user.profile_photo),
                  to: {
                    name: "personalCenter",
                    params: { id: user.provider_id }
                  }
                }
              }),
              _c("p", { staticClass: "info" }, [
                user.name
                  ? _c("span", {
                      staticClass: "name",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.searchKey
                            ? _vm.highlighted(user.name, _vm.searchKey)
                            : user.name
                        )
                      },
                      on: {
                        click: function($event) {
                          return _vm.routeJump({
                            name: "personalCenter",
                            params: { id: user.provider_id }
                          })
                        }
                      }
                    })
                  : _vm._e(),
                user.country && user.country !== "0"
                  ? _c("img", {
                      staticClass: "country",
                      attrs: { src: _vm.getCountryIcon(user.country) }
                    })
                  : _vm._e()
              ]),
              _c(
                "ul",
                { staticClass: "tags" },
                [
                  _vm._l(user.tags, function(tag, index1) {
                    return [
                      _c("li", {
                        key: index1,
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.searchKey
                              ? _vm.highlighted(tag, _vm.searchKey)
                              : tag
                          )
                        }
                      })
                    ]
                  })
                ],
                2
              ),
              _c("actions", {
                attrs: { "action-data": user.actions, "show-chat": true }
              })
            ],
            1
          )
        }),
        0
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          layout: "prev, pager, next",
          "page-size": _vm.pageSize,
          "hide-on-single-page": true,
          total: _vm.total,
          "current-page": _vm.pageNum
        },
        on: { "current-change": _vm.pageChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }