<template>
  <div class="page people">
    <label class="categoryName">
      <i class="el-icon--home" @click="jumpPage({ name: 'home' })"></i>

      <template v-if="cname">
        <i class="el-icon--right"></i>
        <span class="category" @click="backToCategory()">{{ cname }}</span>
      </template>
      <i class="el-icon--right"></i>
      <i class="el-icon-user-solid"></i>
    </label>
    <div class="user-list">
      <div class="item" v-for="(user, index) in users" :key="index">
        <Avatar
          :src="formartImageUrl(user.profile_photo)"
          :to="{ name: 'personalCenter', params: { id: user.provider_id } }"
        />
        <p class="info">
          <span
            class="name"
            v-if="user.name"
            v-html="searchKey ? highlighted(user.name, searchKey) : user.name"
            @click="
              routeJump({ name: 'personalCenter', params: { id: user.provider_id } })
            "
          ></span>
          <img
            v-if="user.country && user.country !== '0'"
            class="country"
            :src="getCountryIcon(user.country)"
          />
        </p>
        <ul class="tags">
          <template v-for="(tag, index1) in user.tags">
            <li
              :key="index1"
              v-html="searchKey ? highlighted(tag, searchKey) : tag"
            ></li>
          </template>
        </ul>
        <actions :action-data="user.actions" :show-chat="true" />
      </div>
    </div>

    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="pageSize"
      :hide-on-single-page="true"
      :total="total"
      :current-page="pageNum"
      @current-change="pageChange"
    ></el-pagination>
  </div>
</template>
<script>
import actions from "@/components/business/web-site/action";
// import { parse } from "path";
import Avatar from "@/components/common/avatar";
export default {
  data() {
    return {
      cname: "",
      cid: 0,
      categorys: [],
      users: [],
      total: 0,
      pageSize: 50,
      pageNum: 1,
      searchKey: "",
      country: ""
    };
  },
  components: {
    actions,
    Avatar
  },
  computed: {
    watchCategorysId() {
      return this.$store.state.webStore.categoryId;
    },
    watchLocation() {
      return this.$store.state.webStore.location;
    }
  },
  watch: {
    watchCategorysId: {
      handler(val) {
        let self = this;
        self.cid = val;
        if (val) self.cname = self.findCurrentCate(val);
        self.getUsers();
      },
      deep: true
    },
    watchLocation: {
      handler(val) {
        this.country = val[1];
        this.getUsers();
      },
      dreep: true
    }
  },
  created() {
    let type = this.$route.params.type;
    let category_id = this.$route.params.categoryId;

    this.cid = parseInt(category_id) || this.$store.state.webStore.categoryId;
    if (this.cid) this.cname = this.findCurrentCate(this.cid);

    if (type) {
      this.$store.dispatch("commitType", parseInt(type));
    }
    if (category_id) {
      this.$store.dispatch("commitCateGoryId", parseInt(category_id));
    }
    if (this.$route.params.key) this.searchKey = this.$route.params.key;
    this.getUsers();
  },
  methods: {
    pageChange(page) {
      this.pageNum = page;
      this.getUsers(page);
    },
    backToCategory() {
      this.$store.dispatch("commitType", 0);
      this.jumpPage({ name: "list", params: { categoryId: this.cid } });
    },
    async getUsers() {
      let self = this;
      let addPagNum = arguments[0];
      if (!addPagNum) this.pageNum = 1;
      let params = {
        categoryId: self.cid,
        pageSize: self.pageSize,
        country: this.country || this.$store.state.webStore.location[1],
        searchKey: this.searchKey || "",
        userId: this.$store.getters.userInfo.id || ""
      };
      if (addPagNum) {
        params.pageNum = addPagNum || 1;
      }
      let result = await self.$http({
        url: "rest/list/v3/getPersonList",
        params,
        withCredentials: false
      });
      self.users = self.formartUserForList(result.data);
      self.total = result.total;
    }
  }
};
</script>
<style lang="stylus" scoped>
.people {
  .categoryName {
    display: flex;
    flex-flow: row;
    font-size: 18px;
    margin: 1.5rem 0 0.9rem 0;
    // justify-content: space-between;
    align-items: center;
    padding: 0 0.9rem 0 0;

    span {
      font-weight: bold;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .el-icon--home, .el-icon-user-solid {
      font-size: 1.2rem;
      color: #33cc66;
      cursor: pointer;

      // margin-right: 0.25rem;
      &:hover {
        opacity: 0.8;
      }
    }

    .el-icon-user-solid {
      color: #333333;
    }

    .el-icon--right {
      color: #666666;
      margin: 0 0.25rem;
    }
  }

  .user-list {
    display: flex;
    flex-wrap: wrap;

    .item {
      display: flex;
      flex-flow: column;
      background: #ffffff;
      margin: 0.35rem 0.35rem 0 0;
      // min-width: 232px;
      width: 18.7%;
      padding: 0.5rem 0.4%;
      align-items: center;

      .avatar {
        width: 6rem;
        height: 6rem;
        border-radius: 6rem;
        object-fit: cover;
        text-align: center;
        line-height: 6rem;
        background: #f5f5f5;
        margin: 0.2rem 0;

        &:before {
          font-size: 3rem;
          color: #999999;
        }
      }

      .info {
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .name {
          font-size: 14px;
          // max-width: 200px;
          color: #222222;
        }

        .country {
          width: 15px;
          object-fit: cover;
          margin: 0 0.5rem;
          img{
            object-fit cover
          }
        }
      }

      .tags {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        margin: 0.5rem 0;
        height: 30px;
        overflow: hidden;

        li {
          padding: 0.1rem 0.5rem;
          border: 1px solid #33cc66;
          border-radius: 5rem;
          font-size: 12px;
          margin: 0.25rem;
          max-height: 20px;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
